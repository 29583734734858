@charset "utf-8";


/**
	define the site global width, styles etc
**/
body {
	max-width:1024px;
}


/**
	masthead area laying out image and site title.
	We need enough height to accommodate the logo
**/
.masthead {
	min-height: 150px;

	#strap {
		left: 30%;
	}
	
	#substrap {
		top: 70%;
		left: 33%;
	}
}
/**
	applied class to site footer area
**/
.mastfooter {

	display: flex;
	justify-content: center;
	align-items: stretch;
	gap: 10px;
	font-size: smaller;

	div {
		width:25%;
	}
}

/**
	logo might appear elsewhere than the masthead so top level class
**/
.site-logo {
	width: 100px;
	height: 100px;
	vertical-align: top;
}

/**
	how the mastodon feed styles (in the front2col layout, but good anywhere
**/
.mastodon-header {
	background-color: #17063B;
	width: 100%;
}

.mastodon-body {
	width: 100%;
}

/**
	applied to a div to contain a figure (image) inline inside a post
	see _includes/inline.html
**/
.imginline {
	
	max-width: 50%;	// the container

	img {
		max-width: 100%;
	}

	figcaption {
		margin-top: 0px;
	}

	figure {	// override w3.css which adds 40px
		margin: 1em;
	}

}

/**
	applied to a div to crete a clickable link to a google photo gallery.
	see _includes/googlegallery.html

**/
.googlegallery {
	width: 30%;
	aspect-ratio: 1 / 1;

	div {
		background-size: cover;
		background-position: center;
		aspect-ratio: 1 / 1;
	}
}

/**
	applied to div container of post's introduction - the teaser/title/date
**/
.intro {
	display: flex;
	align-items: center;
	max-height: 250px;

	.intro-img {
		max-height: inherit;
	}

	.intro-img > img {
		max-width: 100%;
		max-height: inherit;

	}

	.intro-text {
		display: flex;
		flex-direction: column;
		flex-grow: 4;
	}
}

/**
	applied to container div for the front page to layout postcards in a grid pattern
**/
.front {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	gap: 10px;
}

.front:before {
	content: none;
}

/**
	applied to container for the contacts page which has a feed to display
**/
.front2col {
	width: 49%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
	align-content: flex-start;

	div.postcard {
		width: 100%;
		height:  fit-content;
		justify-content: flex-start;
	}	
}


/**
	applied to div that contains the postcard.
	vertical flex of three main panels - header+extract+footer
**/
.postcard {
	float: left;
	width: 49%;
	text-align: justify;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	a {
		text-decoration: none;
	}

	img {
		float: left;
		margin-right: 1rem;
		max-width: 33%;
		max-height: 150px;
	}

	footer div {
		display: flex;
		justify-content:  flex-start;

		.more-link {
			align-self: flex-end;
			margin-left: auto;
		}
	}

}

/**
	An element early in a post that is using a series box gets stretched verically by the ajoining series box. Prevent this.
**/
.serieschop {
	max-width: 60%;
}
.serieschop::before {
	content: none;
}
.serieschop::after {
	content: none;
}

